@font-face {
  font-family: "DMSans-700";
  src: url("./DM_Sans/DMSans-Bold.ttf");
}

@font-face {
  font-family: "DMSans-BoldItalic";
  src: url("./DM_Sans/DMSans-BoldItalic.ttf");
}

@font-face {
    font-family: "DMSans-500";
    src: url("./DM_Sans/DMSans-Medium.ttf");
  }

@font-face {
    font-family: "DMSans-400";
    src: url("./DM_Sans/DMSans-Regular.ttf");
  }
  