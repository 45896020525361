@import '~@angular/material/theming';
@import "../scss/bootstrap.scss";

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

*, *::before, *::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    --scrollbarBG: #e4e4e4;
    --thumbBG: #4e4e4e;
  }
  body::-webkit-scrollbar {
    width: 8px;
  }

  body {
    scrollbar-width: thin;
    scrollbar-color: var(--thumbBG) var(--scrollbarBG);
  }
  ul::-webkit-scrollbar-track {
    background: var(--scrollbarBG);
    border-radius: 8px;
  }
  ul::-webkit-scrollbar-thumb {
    background-color: var(--thumbBG) ;
    border-radius: 8px;
    background-clip: padding-box;  
  }

  @-moz-document url-prefix() {
    .scrollbar {
        overflow: auto;
        width: 0.3em !important;
        scroll-behavior: smooth !important;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
        background-color: #95989E !important;
        outline: 1px solid slategrey !important;
        border-radius: 10px !important;
    }
}

    ::-webkit-scrollbar {
        width: 0.5em !important;
        scroll-behavior: smooth !important;
    }

    ::-webkit-scrollbar-track {
        border-radius: 10px !important;
        box-shadow: inset 0 0 6px #ced4da !important;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 10px !important;
        width: 0.5em;
        background-color: #86878873;
    }